.sale-channel-button {
    bottom: 10px !important;
    position: absolute !important;
}

.pre-sale-checkbox {
    font-size: medium !important;
}

.ui.disabled.input {
    opacity: 1 !important;
}